import z from 'zod';
import { zodTDateIso } from '~/lib/zod';

export interface UserDataNotified {
  notified_invited?: boolean;
  notified_password?: boolean;
}

export interface UserData extends UserDataNotified {
  count_messages_total: number;
  count_messages_open: number;
  count_messages_unread: number;
  count_messages_unreplied: number;
  count_messages_personal_total: number;
  count_messages_personal_open: number;
  count_messages_personal_unread: number;
  count_messages_personal_unreplied: number;
  count_messages_mentions_total: number;
  count_messages_mentions_open: number;
  count_messages_mentions_unread: number;
  count_messages_mentions_unreplied: number;

  first?: string;
  last?: string;
  title?: string;
  password?: string;
  password_change?: boolean;
  reset?: boolean;
  google?: boolean;
  customers: Record<string, {}>;
  editor?: string;
  provider?: string;
  onboarded?: boolean;
  accepted?: TDateISO;
  imported?: boolean;
  sms?: boolean;

  twilio_worker_enabled?: boolean;
  twilio_worker_sid?: string;
  twilio_worker_priority?: number;
}

export function USER_DEFAULT_DATA(): Omit<UserData, 'first' | 'last' | 'email'> {
  return {
    customers: {},
    count_messages_total: 0,
    count_messages_open: 0,
    count_messages_unread: 0,
    count_messages_unreplied: 0,
    count_messages_personal_total: 0,
    count_messages_personal_open: 0,
    count_messages_personal_unread: 0,
    count_messages_personal_unreplied: 0,
    count_messages_mentions_total: 0,
    count_messages_mentions_open: 0,
    count_messages_mentions_unread: 0,
    count_messages_mentions_unreplied: 0,
  };
}

export const InvitedUserSchema = z.object({
  invite_send: z.boolean(),
  invite_accepted: zodTDateIso().optional(),
  invite_notified: z.boolean(),
});
